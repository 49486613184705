/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from 'react';
import { SvgHandler } from '../../svg-handler/svg-handler';
import PropTypes from 'prop-types';
import omit from '@babel/runtime/helpers/objectWithoutProperties';
import getSocialComponentMap from './social-components';
import './social-follow.m.css';

const SocialFollow = props => {
  let {
    socialLinks = {},
    socialStyle = 'circular-color-svg',
    customCssClasses = '',
    backgroundColor,
    border,
    color,
    hasBackground,
    isSidebar,
    contrastAgainstBg
  } = props;
  const filteredLinks = omit(socialLinks, ['google-plus-url']);
  const socialComponents = Object.keys(filteredLinks).map((socialLink, index) => {
    let componentName = '';
    let link = '#';
    if (socialLink.search('-') !== -1 && socialLinks[socialLink]) {
      componentName = socialLink.split('-')[0].toLowerCase();
      link = socialLinks[socialLink];
    }
    const validSocialStyles = [
      'circular-color-svg',
      'circular-plain-svg',
      'plain-svg',
      'plain-color-svg',
      'square-svg'
    ];
    socialStyle = validSocialStyles.indexOf(socialStyle) > -1 ? socialStyle : 'circular-color-svg';
    const socialComponentMap = getSocialComponentMap(socialStyle);
    const iconClassName = {
      facebook: `facebook`,
      twitter: `twitter`,
      instagram: `instagram`,
      linkedin: `linkedIn`,
      youtube: `youtube`,
      whatsapp: `whatsapp`,
      pinterest: `pinterest`,
      telegram: `telegram`,
      reddit: `reddit`,
      jio: `jio`,
      google: `google`
    };
    const invertColor =
      (['circular-plain-svg', 'plain-svg'].indexOf(socialStyle) > -1 ||
        (['plain-color-svg'].indexOf(socialStyle) > -1 && componentName === 'twitter')) &&
      contrastAgainstBg === 'light';
    return (
      componentName && (
        <li data-test-id='social_icons' styleName='social-link' key={`social-component-${index}`}>
          <a href={link} target='_blank' rel='noopener noreferrer me'>
            <SvgHandler
              link={link}
              invertColor={invertColor}
              width='24'
              height='24'
              color={color}
              hasBackground={hasBackground}
              backgroundColor={backgroundColor}
              border={border}
              clazzName={`${iconClassName[componentName]}`}
              svgWrapperClazzName={isSidebar ? 'svg-wrapper' : 'social-svg-wrapper'}
              xlinkHref={socialComponentMap[componentName]} // might screw up .. add mapping for id's
            />
          </a>
        </li>
      )
    );
  });

  return (
    <ul styleName={customCssClasses} className='social-follow-icons'>
      {socialComponents}
    </ul>
  );
};

SocialFollow.propTypes = {
  socialLinks: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
  primaryColor: PropTypes.string,
  hasBackground: PropTypes.bool,
  customCssClasses: PropTypes.string,
  isSidebar: PropTypes.bool,
  socialStyle: PropTypes.string,
  contrastAgainstBg: PropTypes.string
};

export { SocialFollow };
