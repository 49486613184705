/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import facebookPlain from "./svg/facebook-plain.svg";
import instagramPlain from "./svg/instagram-plain.svg";
import linkedinPlain from "./svg/linkedin-plain.svg";
import twitterPlain from "./svg/twitter-plain.svg";
import whatsappPlain from "./svg/whatsapp-plain.svg";
import youtubePlain from "./svg/youtube-plain.svg";
import pinterestPlain from "./svg/pinterest-plain.svg";
import telegramPlain from "./svg/telegram-plain.svg";
import googlePlain from "./svg/google-plain.svg";
import jioPlain from "./svg/jio-plain.svg";
import redditPlain from "./svg/reddit-plain.svg";

export const PlainSocial = {
  facebook: facebookPlain,
  twitter: twitterPlain,
  instagram: instagramPlain,
  linkedin: linkedinPlain,
  youtube: youtubePlain,
  whatsapp: whatsappPlain,
  pinterest: pinterestPlain,
  telegram: telegramPlain,
  google: googlePlain,
  jio: jioPlain,
  reddit: redditPlain
};
